<template>
  <div class="page">
    <h1>Links (you should know)</h1>
    <ul>
      <li v-for="link in links" :key="link.url">
        <a :href="link.url" target="_blank">
          <div class="icon">
            <fa-icon :icon="link.icon || 'link'" />
          </div>
          <div>
            <h3>{{ link.title }}</h3>
            <p>{{ link.description }}</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Links',
  data() {
    return {
      links: [
        {
          url: "https://app.forestry.io",
          title: "LandingPage CMS",
          description: "You can manage the landingpage content here ;)"
        },
        {
          url: "https://app.bynand.de",
          title: "Bynand Production",
          description: "This is the Website Users will see. Don't do wired stuff here!",
          icon: "rocket"
        },
        {
          url: "https://app.dev.bynand.de",
          title: "Bynand Staging",
          description: "This is a copy of production for internal use. You can do experiments here.",
          icon: "rocket"
        },
        {
          url: "https://trello.com/b/LdZz3rcP/bynand",
          title: "Trello",
          description: "This is where we have our tasks.",
          icon: ["fab", "trello"]
        },
        {
          url: "https://bynand.slite.com/",
          title: "Slite",
          description: "An internal wikipedia for everything we have to know at some point."
        },
        {
          url: "https://mail.zoho.eu/",
          title: "E-Mail",
          description: "Our E-Mail provider you can login with your bynand e-mail here.",
          icon: "envelope"
        },
        {
          url: "https://app.sendgrid.com/",
          title: "E-Mail Service (Marketing, App)",
          description: "Our E-Mail templates are here. User-Credentails can be found in Slite."
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

ul {
  margin-top: 100px;

  li {
    list-style: none;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      
      .icon {
        font-size: 32px;
        margin-right: 20px;
        color: $deepsea;
      }

      h3 {
        margin-bottom: 5px;
        color: $deepsea;
      }

      p {
        margin-top: 5px;
        color: $ice;
      }
    }
  }
}

</style>;
